import { useEffect, useState } from 'react';
import { Modal as ModalComponent, Box, Typography, Button, IconButton, Backdrop, Fade } from '@mui/material';
import { IoCloseSharp } from 'react-icons/io5';

const Modal = (props) => {
    const { open, onClose, children, headerTitle = "", size = "lg", ...rest } = props;

    const [siz, setSiz] = useState('lg:max-w-[1280px]')

    useEffect(() => {
        if (size === 'xs') {
            setSiz('lg:max-w-[360px]')
        } else if (size === 'sm') {
            setSiz('lg:max-w-[568px]')
        } else if (size === 'base') {
            setSiz('lg:max-w-[768px]')
        } else if (size === 'normal') {
            setSiz('lg:max-w-[1080px]')
        } else if (size === 'lg') {
            setSiz('lg:max-w-[1280px]')
        } else if (size === 'full') {
            setSiz('lg:max-w-[1920px]')
        }
    }, [])

    const backdropStyle = {
        backgroundColor: localStorage.getItem('color-theme') === 'light' ? '#00000098' : '#ffffff98', // Change this to your desired color
        backdropFilter: 'blur(3px)'
    };

    return (
        <ModalComponent
            open={open}
            onClose={onClose}
            {...rest}
            closeAfterTransition={true}
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
            disableAutoFocus={true}
            keepMounted={false}
            sx={{ zIndex: 50 }}
            BackdropProps={{
                sx: backdropStyle
            }}
        >
            <div className={`relative max-h-[100vh] m-auto w-full md:w-full lg:w-9/12 ${siz} bg-brand-50 dark:bg-brand-950`}>
                {/* HEADER */}
                <div className='sticky font-semibold text-[1.3rem] md:text-[1.6rem] md:text-[1.8rem] z-20 h-[70px] md:h-[80px] w-full bg-brand-200 dark:bg-brand-900 flex items-center p-2 md:p-5 mb-3 text-brand-950 dark:text-white'>
                    {headerTitle}
                    <div className="p-3 absolute right-0">
                        <IconButton onClick={onClose} className="flex items-center justify-center !bg-red-500">
                            <IoCloseSharp size={28} className="text-white dark:text-white" />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div className="p-2 pb-16 md:pb-16 md:p-5 overflow-y-auto max-h-[calc(100vh-70px)] md:max-h-[calc(100vh-80px)]">
                    {children}
                </div>
            </div>
        </ModalComponent>
    );
};

export default Modal;